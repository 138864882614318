import { LucideIcon, TerminalIcon } from 'lucide-react';
import React, { ReactNode } from 'react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { cn } from '@/lib/utils';
import CopyButtonInner from '../buttons/copy-button-inner';

const ErrorAlert = ({
  title = 'Oh no! Something went wrong.',
  description,
  Icon = TerminalIcon,
  iconProps = {},
  className,
  descriptionClassName,
}: {
  description: ReactNode;
  title?: string;
  Icon?: LucideIcon;
  iconProps?: React.SVGProps<SVGSVGElement>;
  className?: string;
  descriptionClassName?: string;
}) => {
  return (
    <Alert className={cn('mgsm-error-alert', className)}>
      {typeof description === 'string'
        ? <CopyButtonInner text={description} siteConfiguration={null} />
        : <Icon className="h-4 w-4" {...iconProps} />
      }
      <AlertTitle className='text-red-600'>{title}</AlertTitle>
      <AlertDescription className={cn(
        'text-gray-500 dark:text-gray-400 w-[90%] flex gap-1',
        descriptionClassName
      )}>
        <span className='truncate'>{description}</span>
      </AlertDescription>
    </Alert>
  );
};

export default ErrorAlert;
