'use client';

import React, { HTMLAttributeAnchorTarget, HTMLAttributes } from 'react';
import { ButtonProps, buttonVariants } from '../ui/button';
import Link, { LinkProps } from 'next/link';
import { cn } from '@/lib/utils';

export type LinkButtonProps = {
  children?: React.ReactNode;
  className?: string;
  href: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  target?: HTMLAttributeAnchorTarget;
  disabled?: boolean;
} & LinkProps & HTMLAttributes<HTMLAnchorElement>;

const LinkButton = ({
  children,
  className,
  href,
  variant,
  size,
  target,
  onClick,
  disabled = false,
  ...props
}: LinkButtonProps) => {
  const isExternal = target === '_blank'
    || href.startsWith('http');
  return (<Link
    href={href}
    target={isExternal ? '_blank' : undefined}
    rel={isExternal ? 'noopener noreferrer' : undefined}
    className={cn('mgsm-link-button', buttonVariants({
      variant,
      size,
    }), disabled && 'opacity-50 cursor-not-allowed', className)}
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (onClick) {
        onClick(e);
      }
    }}
    {...props}
  >
    {children}
  </Link>);
};

export default LinkButton;
